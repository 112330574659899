body{
  background-color: #11111f; 
  overflow-x: hidden;
}

.App {
  text-align: center;
  overflow-x: hidden;
  
 
}

